<template>
  <div class="user-count-echart-box flex flex-column">
    <div class="card-title">{{ props.time === 'today' ? '今日' : '累计' }}用户数据统计</div>
    <div class="canvas-box">
      <div class="flex-1" id="ageCanvas" ref="ageCanvas"></div>
      <div class="flex-1" id="sexCanvas" ref="sexCanvas"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { getUserCount } from "../../../api/count"
import { getDay } from "../../../utils/util"
// 引入echarts
import * as echarts from "echarts"

const props = defineProps({
  time: {
    type: String,
    default: ''
  }
})

const sexCanvas = ref(null)
const ageCanvas = ref(null)

onMounted(() => {
  let data  = {}
  if (props.time === 'today') data.date = getDay()
  getUserCount(data).then(res => {
    let { age, sex } = res;
    let ageFrom = []
    let sexFrom = []
    for(let i in age) ageFrom.push({name: i, value: age[i]}) // 用户年龄统计
    for(let i in sex) sexFrom.push({name: i, value: sex[i]}) // 用户性别统计

    initEchart(sexFrom,sexCanvas, { name: '性别统计', color: ['#3FBCEF', '#EC6B76']})
    initEchart(ageFrom,ageCanvas, {name: '年龄统计', color: ['#314396', '#52FFFF', '#F39D77', '#3EB8B4', '#28B1FF']})
  })
})
// const initUserCount = () => {
//
// }

const initEchart = (valueData, domBox, { name, color }) => {
  let option = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      bottom: '6px',
      left: 'center',
      textStyle: {
        color: '#fff',
        fontSize: '10',
        marginLeft: '10px'
      }
    },
    color,
    series: [
      {
        width:  window.outerWidth < 750 ? '80%' : '50%',
        left: 'center',
        top: -30,
        name: name,
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          textStyle:{
            color:'#FFF'
          },
          show: true,
          position: 'outside'

        },
        emphasis: {
          label: {
            show: true,
            fontSize: '10',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          normal:{
            length:0,   	// 指示线长度
            lineStyle: {
              color: "#FFF"  // 指示线颜色
            }
          },
        },
        data: valueData
      }
    ]
  }

  let canvasDom = echarts.init(domBox.value)
  canvasDom.setOption(option)
}
</script>

<style lang="scss" scoped>
.user-count-echart-box {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/user-ageSex-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: .375rem .375rem .125rem;
  box-sizing: border-box;
  .card-title {
    margin-bottom: .313rem;
  }
  .canvas-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    & > .flex-1 {
      flex: 1;
    }
    #ageCanvas {
      margin-bottom: .25rem;
    }
  }
}
@media (max-width: 750px) {
  .canvas-box {
    display: flex;
    flex-direction: row !important;
    .flex-1 {
      width: 50% !important;
    }
  }
}
</style>
